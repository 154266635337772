@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');


a.navbar-brand {
    white-space: normal;
    text-align: center;
    word-break: break-all;
    color: black;
}



html {
    font-size: 14px;
    background-color: transparent;
    color: black;
}

a.navLink {
    color: black !important;
    background-color: transparent;
    margin-left: 10px;
    padding: 2px;
    font-family: 'Bebas Neue', cursive !important;
    border: 1px black solid;
    border-radius: 5px;
    box-shadow: 2px 2px grey;
}

@media (min-width: 768px) {
    html { font-size: 16px; }
}

.box-shadow { box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05); }

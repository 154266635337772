@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');


@font-face {
    font-family: 'brandon-grotesque-light-58a8a4b38001d';
    src: url('fonts/Brandon-Grotesque-Font-Family/brandon-grotesque-light-58a8a4b38001d.otf') format('opentype');
}

@font-face {
    font-family: 'fontsfree-net-lulo-clean-w01-one-bold';
    src: url('fonts/FontsFree-Net-Lulo-Clean-W01-One-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'brandon-grotesque-black-58a8a3e824392';
    src: url('fonts/Brandon-Grotesque-Font-Family/brandon-grotesque-black-58a8a3e824392.otf') format('opentype');
}

@font-face {
    font-family: 'brandon-grotesque-regular-italic-58a8a456c4724';
    src: url('fonts/Brandon-Grotesque-Font-Family/brandon-grotesque-regular-italic-58a8a456c4724.otf') format('opentype');
}

.sliderDiv{
    margin-top:10px;
}

a {
    color: white;
}

html {
    background-color: #66798c;
    /*background-color: black;*/
    color: white;
}

.navBar {
    background-color: white !important;
}

/*Hero banner on all platforms.*/

.heroBanner {
    background-image: url(./images/wavelengthHero.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: auto;
    width: 100%;
    padding-top: 100px;
    padding-bottom: 100px;
    font-family: 'Bebas Neue', cursive !important;
}

    .heroBanner h1 {
        /*        color: white;*/
        font-size: 7vw;
        text-align: center;
        padding-bottom: 0px;
/*        letter-spacing: 1.8vw;*/
    }

    .heroBanner h2 {
        /*        color: white;*/
        font-size: 2vw;
        text-align: center;
/*        letter-spacing: 0.25vw;*/
    }

    .heroBanner h3 {
        /*        color: white;*/
        font-size: 1.2vw;
        text-align: center;
        /*        letter-spacing: .25vw;*/
/*        font-style: italic;*/
        font-family: 'Bebas Neue', cursive !important;
    }

.aboutTitleDiv {
    margin-top: 20px;
    width: 100%;
}

@media only screen and (max-width: 600px) {
    .aboutTitle {
        font-size: 3vw;
        text-align: center;
/*        letter-spacing: 0.3vw;*/
        font-family: 'fontsfree-net-lulo-clean-w01-one-bold';
    }

    .heroBanner h1 {
        /*        color: white;*/
        font-size: 15vw;
        text-align: center;
        padding-bottom: 0px;
        /*        letter-spacing: 1.8vw;*/
    }

    .heroBanner h2 {
        /*        color: white;*/
        font-size: 4vw;
        text-align: center;
        /*        letter-spacing: 0.25vw;*/
    }

    .heroBanner h3 {
        /*        color: white;*/
        font-size: 4vw;
        text-align: center;
        /*        letter-spacing: .25vw;*/
        /*        font-style: italic;*/
        font-family: 'Bebas Neue', cursive !important;
    }
}

@media only screen and (min-width: 600px) {
    .aboutTitle {
        font-size: 1vw;
        text-align: center;
/*        letter-spacing: 0.3vw;*/
        font-family: 'fontsfree-net-lulo-clean-w01-one-bold';
    }
}

.aboutImage {
    width: 4% !important;
    display: block;
    height: auto;
    margin: auto;
    padding-top: 30px;
    min-width: 100px;
}


.theTeam {
    background-image: url(./images/teamBKG.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    font-size:large;
    height: auto;
    width: 100%;
    font-family: 'brandon-grotesque-light-58a8a4b38001d' !important;
}

    .theTeam table {
        width: 50%;
        margin: auto;
        margin-bottom: 100px;
    }

    .theTeam h1 {
        color: white;
        font-size: xx-large;
        text-align: center;
        padding-top: 200px;
        padding-bottom: 30px;
/*        letter-spacing: 1vw;*/
        font-family: 'fontsfree-net-lulo-clean-w01-one-bold';
    }

    .theTeam h2 {
        color: white;
        font-size: large;
        text-align: center;
        padding-top: 5px;
        padding-bottom: 30px;
/*        letter-spacing: .1vw;*/
        font-family: 'brandon-grotesque-regular-italic-58a8a456c4724';
    }

    .theTeam td {
        padding-left: 50px;
        padding-right: 50px;
    }

.teamImage {
    width: 30% !important;
    height: auto;
    margin: auto;
    margin-left: 35%;
    margin-right: 35%;
}

.discordLogo {
    width: 6%;
    margin: auto;
    margin-top: 50px;
    padding-bottom: 100px;
    margin-left: 47%;
    margin-right: 47%;
}




@media only screen and (max-width: 600px) {
    .mintImage {
        width: 80% !important;
        height: auto;
        display: block;
        margin: auto;
        /*       margin-left: 30%;
        margin-right: 30%;*/
    }

    .mintImageB {
        width: 80% !important;
        height: auto;
        display: block;
        margin: auto;
        /*       margin-left: 30%;
        margin-right: 30%;*/
    }

    .mintTitle {
        margin-bottom: 0 !important;
        padding-bottom: 0 !important;
        color: white;
        font-size: 4vw;
        text-align: center;
        padding-bottom: 20px;
/*        letter-spacing: 0.7vw;*/
        font-family: 'brandon-grotesque-black-58a8a3e824392';
    }
}

@media only screen and (min-width: 600px) {
    .mintImage {
        width: 20% !important;
        height: auto;
        margin: auto;
        margin-left: 40%;
        margin-right: 40%;
    }

    .mintImageB {
        width: 50% !important;
        height: auto;
        margin: auto;
        margin-left: 25%;
        margin-right: 25%;
    }

    .mintTitle {
        margin-bottom: 0 !important;
        padding-bottom: 0 !important;
        color: white;
        font-size: 2vw;
        text-align: center;
        padding-bottom: 20px;
/*        letter-spacing: 0.5vw;*/
        font-family: 'brandon-grotesque-black-58a8a3e824392';
    }
}

.mintAWaveLength {
    margin-bottom: 0 !important;
    padding-bottom: 10px !important;
    color: white;
    text-align: center;
    padding-bottom: 20px;
    padding-top: 50px;
/*    letter-spacing: 0.3vw;*/
/*    font-size: 2vw;*/
    font-family: 'brandon-grotesque-black-58a8a3e824392';
}


.checkAWavelength {
    margin-bottom: 0 !important;
    padding-bottom: 10px !important;
    color: white;
    text-align: center;
    padding-bottom: 20px;
    padding-top: 50px;
    /*    letter-spacing: 0.3vw;*/
    /*    font-size: 2vw;*/
    font-family: 'brandon-grotesque-black-58a8a3e824392';
}


@media only screen and (max-width: 600px) {
    .checkAWavelength {
/*        letter-spacing: 0.8vw;*/
        font-size: 6vw;
    }

    .checkAWavelength {
        /*        letter-spacing: 0.8vw;*/
        font-size: 3vw;
    }
}

@media only screen and (min-width: 600px) {
    .mintAWaveLength {
/*        letter-spacing: 0.3vw;*/
        font-size: 2vw;
    }

    .checkAWavelength {
        /*        letter-spacing: 0.3vw;*/
        font-size: 1vw;
    }
}


.imageCollumn td {
    width: 100px;
}

.tableImage {
    width: 50px;
    height: auto;
    margin-left: 0px;
    margin-top: 0px;
    padding: 0px;
    margin-right: 50px;
}

.aboutCollumnLeft {
    font-family: 'brandon-grotesque-light-58a8a4b38001d';
}

@media only screen and (max-width: 600px) {

    .descriptionCollumn h1 {
        font-family: 'fontsfree-net-lulo-clean-w01-one-bold';
        margin-bottom: 10px;
/*        letter-spacing: 0.3vw;*/
        font-size: 2.5vw;
    }

    .descriptionCollumn h2 {
        font-family: 'brandon-grotesque-light-58a8a4b38001d';
        font-size: 2.8vw;
/*        letter-spacing: 0.4vw;*/
    }

    .infoLeftCollumn {
        width: 100%;
        float: left;
    }

    .infoRightCollumn {
        width: 100%;
        float: left;
    }

    .teamCollumnRight {
        width: 100%;
        float: left;
        padding: 10%;
        /*        padding: 10%;*/
    }

    .teamCollumnLeft {
        width: 100%;
        float: left;
        padding: 10%;
        /*        padding: 10%;*/
    }

    .aboutCollumnLeft {
        width: 100%;
        float: left;
        padding: 5%;
        padding-bottom: 20px;
/*        letter-spacing: 0.25vw;*/
    }

    .aboutCollumnRight {
        width: 100%;
        float: left;
        padding: 2%;
        padding-bottom: 40px;
    }
}

.faqWrapper{
    width:80%;
    display:block;
    margin:auto;
    padding-top:100px;
    padding-bottom:100px;

}

.dropsLinkTableDiv {
    margin:10px;
}

@media only screen and (min-width: 600px) {
    .descriptionCollumn h1 {
        font-family: 'fontsfree-net-lulo-clean-w01-one-bold';
        margin-bottom: 10px;
/*        letter-spacing: 0.1vw;*/
        font-size: .8vw;
    }

    .descriptionCollumn h2 {
        font-family: 'brandon-grotesque-light-58a8a4b38001d';
        font-size: .8vw;
/*        letter-spacing: 0.1vw;*/
    }
    .infoSection {
        width: 100%;
        /*height: 50px;*/
        /*border: solid pink 10px;*/
        /*position: absolute;*/
        display:block;
        float:left;
        /*        float:left;
*/
    }

    .centralMainPage, .centralMainPagePlayer, .navbar {
        /*        width: 50%;*/
    }

    .dropsLinkTableDiv {
        margin-top: 100px;
        width: 100%;
        display: block;
        float: left;
        margin: auto;
        margin-top: 100px;
        margin-bottom: 100px;
    }

    .linkTable {
        /*text-align: center;*/
        margin-top: 100px;
        width: 70%;
        margin-left: 15%;
        margin-right: 15%;
        /*display: block;*/
        /*float: left;*/
    }

        .linkTable img {
            max-width: 200px;
        }

    .infoLeftCollumn {
        width: 42.5%;
        float: left;
    }

    .infoRightCollumn {
        width: 57.5%;
        float: left;
    }

    .teamCollumnRight {
        width: 50%;
        float: right;
        padding: 5%;
    }

    .teamCollumnLeft {
        width: 50%;
        float: left;
        padding: 5%;
    }

    .aboutCollumnLeft {
        width: 50%;
        float: left;
        padding: 2%;
        padding-left: 5%;
        padding-right: 5%;
        font-size: 1vw;
        text-align: left;
    }

    .aboutCollumnRight {
        width: 50%;
        float: right;
        padding: 2%;
    }

    .centerText {
        width: 100%;
        text-align: center;
        display: block;
        margin: auto;
        float: left;
        padding: 10%;
        font-size: 2vw;
    }
}

.halfCollumn {
    font-family: 'Brandon Grotesque' !important;
    font-size: medium;
    width: 50%;
    padding: 10px;
    /*    padding*/
}

.navbarlink {
    float: left !important;
    margin: 5px;
    width: 20px;
    height: auto;
}

.connectButtonTop {
    width: 40%;
    margin: auto;
    height: 10%;
    margin-top: 0%;
    /*    margin-bottom:20px;*/
    float: right;
    color:black !important;
}
.connectButton a{
    color:black !important;
}

.connectButtonImage {
    width: 100%;
}

.linkIcons {
    width: 30%;
    float: left;
}

body {
    /*    background-color: rgb(35,31,32);*/
    color: white;
    /*    background-image: url("/images/neuralinkBKG.png");*/
}

.container {
    /*    background-image: url("/images/neuralinkBKG.png");*/
}

code {
    color: #E01A76;
}

select#mintNoDD {
    max-width: 100px;
    width: 20% !important;
    border-radius: 10px;
    margin: auto;
    display: block;
    color: black;
    font-family: 'Brandon Grotesque', Calibri;
    font-size: medium;
    text-align: center;
/*    margin-bottom: px;*/
}

.bannerImage {
    width: 100% !important;
    margin-left: 0% !important;
    margin-right: 0% !important;
    margin-bottom: 0px !important;
    margin-top: 0px;
}

    .bannerImage h1 {
        font-size: xx-large;
        text-align: center;
        /*    padding: 200px !important;*/
        width: 100%;
    }

.inLineImage {
    width: 40%;
    height: auto;
    margin: auto;
    display: inline-block;
    /*    float:left;*/
    margin-left: 5%;
    margin-right: 5%;
    vertical-align: middle;
}

.inLineText {
    width: 40%;
    height: auto;
    display: inline-block;
    padding: 0;
    /*    float: left;*/
    margin-left: 5%;
    margin-right: 5%;
    vertical-align: middle;
}

    .inLineText h1 {
        font-size: xx-large;
        padding-bottom: 30px;
    }

.block {
    margin-top: 5%;
}

/*#heroDiv {
    height: 80vh;
    width: 100%;
    background-size: 100%;
    position: relative;
    z-index: 1;
}

    #heroDiv video, #heroDivFooter video {
        width: 100%;
        height: 100%;
        position: absolute;
        object-fit: cover;
        z-index: -10;
    }*/

/*.backgroundImage {
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
    z-index: -10;*/
/*        border-radius:10px;*/
/*        display:*/
/*}*/

/*#heroDivFooter {
    height: 80vh;
    width: 100%;
    background-size: 100%;
    margin-top: 100px;*/
/*    display: block;*/
/*position: relative;
}

#heroButtons {
    width: 80%;
    margin: auto;
    display: block;
    z-index: 1;
}*/
/*
.nav-link {
    display: inline-block;
}*/

/*#journalPage label {
    width: 100%;
    height: auto;
    font-size: medium;
    color: white;
}*/

/*@media (min-width: 800px) {
    .heroButton, .heroButtonTwo, .heroButtonThree {
        font-size: x-large;
        width: 20%;
        border: 5px solid white;
    }

    .inLineText, .inLineImage {
        width: 40%;
    }
}

@media (max-width: 800px) {

    .heroButton, .heroButtonTwo, .heroButtonThree {
        font-size: x-small;
        width: 20%;
        border: 2px solid white;
        margin: 0;
    }

    .inLineText, .inLineImage {
        width: 80%;
    }
}*/

/*.heroButton, .heroButtonTwo, .heroButtonThree {
    padding: 2px;
    z-index: 5;
    text-align: center;
    margin-top: 5%;
    font-family: 'Bebas Neue', cursive !important;
    background-color: rgba(0,0,0,0.3);
}*/

/*.heroButton {
    margin-left: 25%;
    margin-right: 5%;
}


.heroButtonTwo {
    margin-left: 5%;
    margin-right: 25%;
}

.heroButtonThree {
    margin-left: 40%;
    margin-right: 40%;
    margin-bottom: 5%;
}*/

/*.logoHeroSmall {
    z-index: 5;
    width: 20%;
    height: auto;
    margin-left: 2vw;
    padding-top: 2vw;
}*/

/*.logoHeroMain {*/
/*    border: 1px solid white;*/
/*display: block;
    margin: auto;
    padding-top: 100px;
    z-index: 5;
    width: 40%;
}*/

/*button.mmButtonIcon {
    background-color: transparent;
    border: none;
    vertical-align: central;
    margin: auto;
    padding-top: 15px;*/
/*    height:100%;*/
/*padding-left: 10px;
    color: transparent;*/
/*    padding-top:14px;*/
/*}

.video-responsive {
    position: relative;
    width: 100%;
    height: auto;
    border: none;
    margin: auto;
    display: block;
}*/

/*    .video-responsive iframe {
        height: 100%;
        width: 100%;
        position: relative;
    }


button.mmButtonIcon img {
    width: 1.5vw;
    height: 2vw;
}*/

.AddressText label {
    text-overflow: clip;
    width: 2px;
    font-size: smaller;
    margin-top: 10px;
}

/*.centralText {
    text-align: center;
    font-size: x-large;
}

    .centralText p {
        padding-top: 10px;
        text-align: center;
        font-size: medium;
    }

    .centralText h3 {
        text-align: center;
        font-size: medium !important;
    }

.centerButton {
    margin: auto;
    display: block;
    width: 100px;
    height: 50px;
    font-size: medium;
    font-style: oblique;
    border-radius: 10px;
    border-color: white;
    box-shadow: 0 .25rem .75rem rgba(255, 255, 255, .2);*/
/*    background-color: rgb(35,31,32);*/
/*background-color: transparent;
    color: white;
}*/



.walletButtonDiv {
    background-color: transparent !important;
    padding: 0px;
    margin: 0px;
    height: 100%;
}


div.container {
    width: 100%;
    height: 100%;
    max-width: 100%;
    padding: 0px;
    margin: 0px;
}

div.centralMainPage {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
}

    div.centralMainPage h3 {
        text-align: center;
        align-content: center;
        /*    font-family: 'brandon-grotesque-light-58a8a4b38001d' !important;*/
    }

div.centralMintPage {
    width: 100%;
    min-height: 100vh;
    margin-left: 0%;
    margin-right: 0%;
    background-color: black;
}

    div.centralMintPage h3 {
        text-align: center;
        align-content: center;
        /*    font-family: 'brandon-grotesque-light-58a8a4b38001d' !important;*/
    }

.mmButtonIcon img, button.mmButtonIcon {
    margin-left: auto !important;
    margin-right: auto !important;
    display: block;
    color: white;
}

input{
    color:white !important;
    background-color:transparent !important;
    margin-top:20px !important;
    margin-bottom:20px !important;
    width:100px !important;
    border: solid 1px white;
    text-align:center !important;
    border-radius:5px !important;
}

.accountModal {
    width: 50%;
}

.errorMessage {
    text-align: center;
    font-family: 'brandon-grotesque-regular-italic-58a8a456c4724';
}

.centralLabel {
    padding-bottom: 20px;
    padding-top: 20px;
    text-align: center;
    font-size: medium;
}

a.centralLabel {
    color: blue !important;
}

.soldOut {
    padding-bottom: 20px;
    padding-top: 20px;
    text-align: center;
    font-size: xx-large;
}

.soldOut a{
/*    color:blue;*/
}

.onOpenSea {
    padding-bottom: 20px;
    padding-top: 20px;
    text-align: center;
    font-size: large;
}

@media only screen and (min-width: 600px) {
    .walletStuff button {
        width: 60%;
    }

    .walletStuff {
        width: 20%;
        margin-left: 40%;
        margin-right: 40%;
    }
}

@media only screen and (max-width: 600px) {
    .walletStuff button {
        width: 100%;
    }

    .walletStuff {
        width: 60%;
        margin-left: 15%;
        margin-right: 15%;
    }
}


    .walletStuff {
        /*width: 20%;*/
        height: 100%;

        background-color: transparent !important;
        /*    margin: 10px auto;*/
        display: block;
        /*    padding-bottom: 50px;*/
        padding-top: 0px;
        text-align: center;
        /*    float:right;*/
        display: block;
        margin:auto;
    }

        .walletStuff button {
            height: 90%;
            font-size: medium;
            font-style: oblique;
            border-radius: 10px;
            background-color: white;
            color: black;
            border: 1px solid white;
            margin: auto;
            display: block;
            margin-top: 0%;
            margin-bottom: 5%;
        }

        .walletStuff img {
            width: 80%;
            height: auto;
            padding: 2%;
            margin-left: 10%;
        }

    .mintSection {
        margin: auto;
        display: block;
        text-align: center;
        width: 60%;
        padding-top: 30px;
        padding-bottom: 30px;
        /*    font-family: 'Bebas Neue', cursive !important;*/
    }

        .mintSection button {
            margin: auto;
            display: block;
            width: 100px;
            height: 50px;
            font-size: medium;
            font-style: oblique;
            border-radius: 10px;
            border-color: white;
            box-shadow: 0 .25rem .75rem rgba(255, 255, 255, .2);
            border: 1px solid white;
            background-color: transparent;
            color: white;
            visibility: visible !important;
            margin-bottom:10px;
        }

        .mintSection label {
            margin: auto;
            display: block;
            width: 50%;
            height: 50px;
            font-size: medium;
            font-style: oblique;
            border-radius: 10px;
            border-color: white;
            background-color: transparent;
            color: white;
            visibility: visible !important;
        }

    @media (min-width: 600px) {

        .leftToMint {
            margin: auto;
            display: block;
            text-align: center;
            width: 60%;
            padding-top: 10px;
            padding-bottom: 10px;
            font-family: 'brandon-grotesque-light-58a8a4b38001d' !important;
        }
    }

    @media (max-width: 600px) {

        .leftToMint {
            margin: auto;
            display: block;
            text-align: center;
            width: 100%;
            padding-top: 10px;
            padding-bottom: 10px;
            font-family: 'brandon-grotesque-light-58a8a4b38001d' !important;
        }
    }

    .leftToMint label {
        margin: auto;
        display: block;
        width: 50%;
        height: 50px;
        font-size: medium;
        font-style: oblique;
        border-radius: 10px;
        border-color: white;
        background-color: transparent;
        visibility: visible !important;
    }

    /*.modalDiv {
    visibility: hidden;
}*/


.linkImage {
    /*width:50%;*/
    /*height:50% !important;*/
}

linkTableSmaller {
    border-spacing: 20px 15px;
    border-collapse: collapse;
    width: 10%;
    margin-left: 50%;
    margin-right: 50%;
}

.linkTableSmaller td {
    /*border: 1px solid white;*/
    padding: 5px;
    vertical-align: middle;
    /*width:40px;*/
    /*margin:40px;*/
}

.linkTable {
    /*text-align: center;*/
    /*    width: 100%;
    margin-left:0%;
    margin-right:0%;*/
    /*height:100%;*/
    
    /*padding:200px;*/
    border-spacing: 20px 15px;
    border-collapse: collapse;
    /*display:block;*/
}

    .linkTable td {
        /*border: 1px solid white;*/
        padding: 5px;
        vertical-align: middle;
        /*width:40px;*/
        /*margin:40px;*/
    }

.roundedCorners img {
    border-radius: 50px !important;
    /*border:solid white 10px;*/
}

.roundedCorners {
    border-radius: 30px !important;
    /*border: solid white 10px;*/
}

